.submit {
    color: #078548;
}
.submit-div {
    margin-left: -100px;
}

.submit-div img {
    position: relative;
    left: 130px;
    bottom: 95px;
}

@media only screen and (max-width: 767px) {
    .submit-div {
        margin-left: 0;
    }
    .submit-div img {
        position: relative;
        left: 0;
        bottom: 0;
        padding: 20px 0;
    }
}