.nav-link {
  font-family: 'Montserrat' sans-serif !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  padding-right: 20px !important;
  cursor:pointer;
}
.nav-link::before {
  content: ' ';
  height: 100px;
  width: 100px;
  background-image: url('../../assets/images/about_icon.svg');
}

.nav-link:last-child {
  margin-right: 0 !important;
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover,
.active.nav-link {
  color: #feb200 !important;
}
.unique-nav .nav-link:focus,
.unique-nav .nav-link:hover {
  color: #feb200 !important;
}

.navbar-light .navbar-toggler {
  border-color: transparent !important;
}

.navbar {
  background-color: #fff !important;
}

.scroll-shadow {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
}

.navbar-toggler:focus {
  box-shadow: none !important;
}

.custom-dropdown {
  width: 930px;
  height: auto;
  color: #000000;
  background-color: #FFFFFF;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  padding: 20px;
  z-index: 3;
  position: fixed;
  top: 4.5rem;
  right: 50%;
  left: 50%;
  transform: translateX(-60%);
}
.custom-dropdown h3 {
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
}

.custom-dropdown p {
  cursor: pointer;
  font-style: normal;
  color:  #000000;
  font-weight: 600;
  font-size: 14px;
}
.custom-dropdown > div:hover > h3,
.custom-dropdown > div > div:hover > h3,
.custom-dropdown > div.active h3,
.custom-dropdown > div div.active h3 {
  color: #feb200;
}
.custom-dropdown > div:hover,
.custom-dropdown > div > div:hover {
  cursor: pointer;
}

.unique-nav a {
  display: block !important;
  color: black;
}

.logo-main {
  height: 100px;
}

@media only screen and (max-width: 1366px) {
  .custom-dropdown {
    transform: translateX(-50%);
  }
}
@media only screen and (max-width: 1200px) {
  .custom-dropdown {
    transform: translateX(-50%);
  }
}

.close {
  cursor: pointer;
  margin: 30px;
  display: flex;
  justify-content: flex-end;
}

@media only screen and (max-width: 992px) {
  .nav-link {
    margin-right: 0 !important;
    color: #A77300 !important;
  }
  .dropd {
    display: none;
  }
  #basic-navbar-nav {
    display: none !important;
  }
  .unique-nav {
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 1031;
    position: fixed;
    top: 0;
    left: 0;
    padding-right: 20px;
  }
  .unique-nav a {
    color: #A77300;
  }
  .navbar--items {
    display: flex;
    margin-left: 20px;
  }
  .active.nav-link {
    color: #A77300 !important;
  }
  .navigate {
    gap: 0;
  }
}

.navigate {
  justify-content: space-between !important;
}


@media only screen and (min-width: 992px) {
  .navigate {
    justify-content: flex-start !important;
    gap: 50px;
  }
  .unique-nav {
    display: none;
  }
  .navbar--items img {
    display: none;
  }
}
@media only screen and (max-width: 375px) {
  .logo-main {
    width: 200px;
  }
}

.mobile--items {
  margin: 20px;
  color: #A77300;
}
