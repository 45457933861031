.custom-margin{
    margin-left: 37px;
}
.custom-width{
    width: 75%;
}
@media only screen and (max-width: 767px) {
    .custom-width {
        width: 85%;
    }
}
@media only screen and (max-width: 600px) {
    .custom-width {
        width: 94%;
    }
}

.form-select {
  background-color: #f5f5f5 !important;
  border: none !important;
  padding: 10px 20px !important;
  color: #6E757C !important;
}

.audit--btn {
  background-color: #E8AC25 !important;
  height: 45px;
  padding: 0 !important;
}