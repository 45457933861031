
/* home header css starts */
.home-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.home-title {
  width: 550px;
  font-size: 55px;
  font-weight: 600;
  line-height: 65px;
  margin-bottom: 20px;
}

.home-banner img {
  display: none;
}

.home-des {
  width: 550px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: rgba(0, 0, 0, 0.6);
}

.lg-img-home {
  display: block;
}

@media only screen and (max-width: 1024px) {
  .home-title {
     width: 456px;
     font-size: 50px;
  }
  .home-des {
     width: 417px;
     font-size: 16px;
  }
  .lg-img-home {
     width: 450px;
  }
}



@media only screen and (max-width: 992px) {
  .home-header {
     flex-direction: column;
  }
  .home-banner {
     text-align: center;
  }
  .home-banner img {
     width: 300px;
     display: inline-block;
  }
  .home-title {
     width: 321px;
     font-size: 40px;
  }
  .home-des {
     width: 400px;
     padding: 0 10px;
  }
  .center {
     display: flex;
     justify-content: center;
  }
  .home-button {
     display: flex;
     justify-content: center;
  }
  .lg-img-home {
     display: none;
  }
}

@media only screen and (max-width: 400px) {
  .home-des {
     width: 375px;
  }
}

@media only screen and (max-width: 380px) {
  .home-title {
     width: 300px;
     font-size: 38px;
  }
  .home-des {
     width: 350px;
  }
}

@media only screen and (max-width: 350px) {
  .home-des {
     width: 330px;
  }
}

@media only screen and (max-width: 320px) {
  .home-des {
     width: 310px;
  }
}

/* home header css ends */


/* home who we are css starts */
.who-title {
  color: #3F3D56;
  margin-bottom: 20px;
  font-size: 45px;
  font-weight: 600;
}

.who-img {
  display: none;
  text-align: center;
}
.whoWe-img {
  display: block;
}

@media only screen and (max-width: 1024px) {
  .who-title {
     font-size: 38px;
  }
  .whoWe-img {
     width: 450px;
  }
}


@media only screen and (max-width: 992px) {
  .who-title {
     text-align: center;
     margin-bottom: 0;
  }
  .who-img-cover {
     text-align: center;
  }
  .who-img {
     width: 400px;
     display: inline-block;
     margin-top: 25px;
     margin-bottom: 20px;
  }
  .who-we-des {
     padding: 0 10px;
  }
  .whoWe-img {
    display: none;
  }
}

@media only screen and (max-width: 425px) {
  .who-title {
     font-size: 33px;
  }
  .who-img {
     width: 340px;
  }
}

@media only screen and (max-width: 375px) {
  .who-img {
     width: 300px;
  }
}

/* home who we are css ends */

/* speak count css starts */
.speak-counter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #2F2D48;
  border-radius: 1rem;
}

.count-title {
  color: #fff;
  font-size: 40px;
}

.speak {
  padding: 0 3rem;
}

.count-padding {
  padding: 0 30px;
}
.counter-gap {
  gap: 100px;
}

.circle-img {
  display: block;
  width: 100%;
  height: auto;
}

.line-height {
  line-height: 15px;
}

@media only screen and (max-width: 1024px) {
  .count-title {
     font-size: 32px;
  }
}

@media only screen and (max-width: 992px) {
  .count-title {
     text-align: center;
     font-size: 30px;
  }
  .speak {
     padding: 0 2px;
  }
  .count-padding {
     padding: 0 10px;
  }
  .speak-counter {
     display: block;
     padding: 10px;
  }
  .counter-gap {
     gap: 80px;
     justify-content: center;
  }
  .circle-img {
     display: none;
  }
}

@media only screen and (max-width: 500px) {
  .speak .count-title {
    font-size: 21px;
  }
  .speak .count-p {
    font-size: 13px;
  }
}

@media only screen and (max-width: 420px) {
  .speak .count-title {
    font-size: 20.5px;
  }
  .speak .count-p {
    font-size: 12px;
  }
}

@media only screen and (max-width: 360px) {
  .counter-gap {
     gap: 30px;
  }
}

@media only screen and (max-width: 320px) {
  .speak .count-title {
    font-size: 17px;
  }
  .speak .count-p {
    font-size: 10px;
  }
}
/* speak count css ends */

/* what we do css starts */

.what-do {
  padding-right: 100px !important;
  padding-left: 100px !important;
}

.what-title {
  color: #3F3D56;
  margin-bottom: 26px;
  font-weight: 600;
}

.whatdo-card {
  width: 230px;
  min-height: 220px;
  padding: 18px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.what-view-button {
  display: none;
}

.what-description {
  width: 83%;
}

.what-do-title {
  font-size: 22px;
}

.whatdo-more {
  color: #E7AA27;
  font-size: 15px;
}

.what-gap {
  gap: 60px;
}

.view-all {
  display: block;
}


@media only screen and (max-width: 1024px) {
  .what-do {
     padding-right: 10px !important;
     padding-left: 10px !important;
  }
  .whatdo-card {
     width: 220px;
  }
  .what-description {
     width: 95%;
  }
  .meeting-img {
     width: 450px;
  }
}

@media only screen and (max-width: 992px) {
  .what-description {
     width: 96%;
  }
  .what-do h1 {
     text-align: center;
  }
  .whatdo-card {
     width: 200px;
  }
  .what-do-title {
     font-size: 20px;
     font-weight: 600;
  }
  .whatdo-more {
     font-size: 15px;
     font-weight: 600;
  }
  .what-view-button {
     display: block;
     text-align: center;
  }
  .what-gap {
     gap: 35px;
  }
  .view-all {
     display: none;
  }
  .meeting-img {
     width: 100%;
  }
}

@media only screen and (max-width: 656px) {
  .what-description {
     padding: 0 10px;
  }
}

@media only screen and (max-width: 450px) {
  .whatdo-card {
    width: 350px;
 }
 .what-do-title {
    font-size: 19px;
 }
 .whatdo-more {
    font-size: 16px;
 }
}

@media only screen and (max-width: 400px) {
  .whatdo-card {
     width: 310px;
  }
  .what-do-title {
     font-size: 20px;
  }
  .whatdo-more {
     font-size: 15px;
  }
}

@media only screen and (max-width: 380px) {
  .what-gap {
     gap: 16px;
  }
}

@media only screen and (max-width: 330px) {
  .whatdo-card {
     width: 270px;
  }
  .what-gap {
     gap: 10px;
  }
}
/* what we do css ends */

/* review css starts */

.review-title-home {
  font-size: 37px;
  padding: 0 10px;
  margin-bottom: -17px;
}

@media only screen and (max-width: 800px) {
  .review-title-home {
    font-size: 34px;
  }
}
@media only screen and (max-width: 500px) {
  .review-title-home {
    font-size: 26px;
  }
}

@media only screen and (max-width: 380px) {
  .review-title-home {
    font-size: 19px;
  }
}

@media only screen and (max-width: 330px) {
  .review-title-home {
    font-size: 18px;
  }
}
/* review css ends */

/* follow us css starts */

.follow-list {
  color: #000000;
  font-size: 18px;
}

.follow-content {
  gap: 9rem;
}

@media only screen and (max-width: 1024px) {
  .follow-content {
     gap: 1rem;
  }
  .follow-img img{
     width: 450px;
  }
}
@media only screen and (max-width: 992px) {
  .follow-img img{
     width: 317px;
  }
  .follow-content {
     gap: 4rem;
  }
}
@media only screen and (max-width: 656px) {
  .follow-el {
     padding-left: 8px;
  }
}

/* follow us css ends */

/* review css starts */
.review-items {
   padding: 0 50px;
}

@media only screen and (max-width: 375px) {
   .review-items {
      padding: 0 30px;
   }
}
@media only screen and (max-width: 320px) {
   .review-items {
      padding: 0 20px;
   }
}
/* review css ends */

/* latest blog css starts */
.blog-img-1 {
   border-radius: 1rem;
   width: 100%;
   height: 300px;
 }
 
 .blog-img-2,
 .blog-img-3 {
   border-radius: 1rem;
   height: 18rem;
 }
 
 .read-more {
   color: #e8ac25;
   text-decoration: underline;
   cursor: pointer;
   font-size: 19px;
 }
 
 .latestblog__container {
   display: grid;
   grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
   grid-template-rows: 1fr 1fr;
   gap: 40px;
 }
 
 .blog1 {
   grid-column: 1/3;
   grid-row: 1/3;
 }
 
 .blog2 {
   display: flex;
   grid-column: 3/6;
   grid-row: 1/2;
   align-items: center;
 }
 
 .blog3 {
   display: flex;
   grid-column: 3/6;
   grid-row: 2/3;
   align-items: center;
 }
 
 .blog1 > .blog__text {
   margin-inline: 10px;
   margin-block: 20px;
 }
 
 .blog2 > .blog__text,
 .blog3 > .blog__text {
   margin-inline: 20px;
   margin-block: 10px;
 }
 
 .latestblog__button {
   margin-top: 3rem;
   display: block;
   margin-inline: auto;
   background-color: #e7aa27;
   color: #fff;
   border-radius: 0.25rem;
   padding-block: 1rem;
   padding-inline: 1.5rem;
   font-weight: 700;
   font-size: 1rem;
 }
 
 @media only screen and (max-width: 1200px) {
   .latestblog__container {
     grid-template-columns: 1fr;
     grid-template-rows: 1fr 1fr 1fr;
     column-gap: 0;
   }
   .blog-img-1,
   .blog-img-2,
   .blog-img-3 {
     width: 340px;
     object-fit: cover;
     height: 300px;
   }
   .blog1,
   .blog2,
   .blog3 {
     grid-column: 2/4;
     display: flex;
     align-items: center;
   }
   .blog1 {
     grid-row: 1/2;
   }
   .blog2 {
     grid-row: 2/3;
   }
   .blog3 {
     grid-row: 3/4;
   }
   .blog1 > .blog__text,
   .blog2 > .blog__text,
   .blog3 > .blog__text {
     margin: 20px;
   }
 }
 
 @media only screen and (max-width: 768px) {
   .blog-img-1,
   .blog-img-2,
   .blog-img-3 {
     width: 100%;
     object-fit: cover;
     height: 300px;
   }
   .blog1,
   .blog2,
   .blog3 {
     grid-column: 2/4;
     display: block;
   }
 }
 
 @media only screen and (max-width: 330px) {
   .blog1 > .blog__text,
   .blog2 > .blog__text,
   .blog3 > .blog__text {
     padding-top: 5px;
     margin: 12px;
   }
 }

/* latest blog css ends */