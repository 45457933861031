.priceLink {
    color: #DF9135;
    display: block;
    margin: 10px 0;
    cursor: pointer;
}
.priceLink:hover {
    color: #ffa238;
}
.planpriceCard {
    width: 300px;
    border: 1px solid #DF9135;
    border-radius: 5px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}