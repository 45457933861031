.service-background {
  background-color: #F9F8F8;
}
.service-flex{
  display: flex;
  justify-content: center;
}
.service-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 45px;
}

@media only screen and (max-width: 1200px) {
  .service-grid {
      grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 992px) {
  .align {
    text-align: center;
    padding-left: 0;
  }
  .service-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media only screen and (min-width: 1360px) {
  .align {
    width: 1287px;
    margin: 0 auto;
  }
}
