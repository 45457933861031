.main {
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.main span {
  background-color: beige;
  padding: 1rem 2rem;
  font-size: 1.5rem;
  border-radius: 3px;
  /* font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif; */
  font-weight: 600;
}
.coming_soon {
  /* border: 3px solid red; */
  max-width: 100%;
  width: 90%;
  aspect-ratio: 2/1;
}
.version {
  position: absolute;
  right: 2rem;
  bottom: 2rem;
  font-weight: 600;
  color: gray;
  border: 0.14rem solid gray;
  padding: 0.5rem 1rem;
  border-radius: 0.6rem;
  background-color: white;
}
