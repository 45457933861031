@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  /* it also disables input typing for ios mobiles... need to find some other solution to block copy=pasting */
  /* user-select: none; supported by Chrome and Opera */
  /* -webkit-user-select: none; Safari */
  /* -khtml-user-select: none; Konqueror HTML */
  /* -moz-user-select: none; Firefox */
  /* -ms-user-select: none; Internet Explorer/Edge */
}
a:hover,
a:visited,
a:link,
a:active {
  text-decoration: none;
}

body, p, h1, h2, h3, h4, h5, h6, div {
  font-family: 'Montserrat', sans-serif;
}

button {
  outline: none;
  background: none;
  border: none;
}
