.auth-background {
   margin-top: -6rem;
   height: 100vh;
   background: linear-gradient(
      to right,
      #E6A427 0%,
      #E6A427 50%,
      #ffffff 50%,
      #ffffff 100%
   );
}

.back-arrow {
   font-size: 35px;
   color: #000000;
   cursor: pointer;
   position: absolute;
   top: 25px;
   left: 25px;
}

.auth-title {
   font-weight: 700;
   font-size: 25px;
   margin: 30px 0;
}

.auth {
   display: flex;
   flex-direction: row;
   justify-content: space-around;
   align-items: center;
   height: 100vh;
}

.auth-1 .auth-img {
   width: 100%;
}
.auth-2 {
   width: 400px;
}

.auth-input {
   margin-bottom: 30px;
}

.auth-input input {
   width: 100%;
   height: 40px;
   background: #F5F5F5;
   border: none;
   padding: 12px;
   border-radius: 5px;
   outline: none;
   font-weight: 500;
   font-size: 16px;
}

.auth-input input:focus {
   border: 2px solid #E7AA27;
}

.check-box {
   display: flex;
   justify-content: space-between;
   margin-bottom: 10px;
}

.forget {
   color: #E6A428;
   cursor: pointer;
}
.remember {
   color: #838383;
}
.auth-btn {
   background-color: #E7AA27;
   color: #ffffff;
   width: 100%;
   height: 50px;
   border-radius: 6.41379px;
   font-weight: 700;
   font-size: 20px;
   margin-bottom: 13px;
}

.shift-terminal {
   font-weight: 500;
   font-size: 18px;
}

.shift {
   color: #DE8E37;
}

.social-login {
   border: 1px solid #E4D9D9;
   text-align: center;
   width: 100%;
   padding: 10px 0;
   border-radius: 5px;
   margin-bottom: 15px;
   cursor: pointer;
}

.social-login span {
   font-weight: 600;
   font-size: 15px;
   color: #000000;
}

@media only screen and (max-width: 992px) {
   .auth-background {
      background: linear-gradient(
         to bottom,
         #E6A427 0%,
         #E6A427 30%,
         #ffffff 30%,
         #ffffff 100%
      );
   }
   .auth {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
   }
   .auth-2 {
      padding-bottom: 70px;
   }
}

@media only screen and (max-width: 450px) {
   .auth-1 {
      text-align: center;
   }
   .auth-1 .auth-img {
      width: 90%;
      padding-top: 20px;
   }
   .auth-2 {
      width: 340px;
      padding-bottom: 70px;
   }
}
@media only screen and (max-width: 325px) {
   .auth-2 {
      width: 300px;
      padding-bottom: 70px;
   }
}