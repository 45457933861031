
/* about header css starts */
.about--header--main {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.about {
  margin-top: 40px;
  margin-bottom: 60px;
}
.about-title {
  font-size: 44px;
  font-weight: 600;
  margin-bottom: 25px;
}

.about--header--image {
  display: inline-block;
}
.about--header--image--2 {
  display: none;
}

.about-description-2 {
  display: none;
}

.about-description {
  display: block;
}

@media only screen and (max-width: 1025px) {
  .about--header--image {
    width: 415px;
  }
  .about-title {
    font-size: 27px;
    font-weight: 600;
  }
  .about-header-des {
    font-size: 16px;
  }
}

@media only screen and (max-width: 992px) {
  .about-title {
    font-size: 30px;
    font-weight: 600;
  }
  .about--header--image {
    display: none;
  }
  .about-img-cover {
    text-align: center;
    margin-bottom: 21px;
  }
  .about--header--image--2 {
    display: inline-block;
  }
  .about-header-des {
    text-align: center;
    font-size: 18px;
    padding: 0 20px;
  }
  .about {
    margin-top: 40px;
    margin-bottom: -5px;
  }
}

@media only screen and (max-width: 767px) {
  .about--header--image {
    width: 350px;
  }
  .about--header--main {
    flex-direction: column;
    flex-wrap: wrap;
  }
  .about--header--image--2 {
    width: 300px;
  }
  .about-header-des {
    padding: 0 15px;
  }
  .about-description {
    display: none;
  }
  .about-description-2 {
    display: block;
  }
  .about-title {
    font-size: 25px;
    font-weight: 600;
    padding: 0 4px;
  }
  .about-img-cover {
    text-align: center;
  }
}

@media only screen and (max-width: 320px) {
  .about-title {
    padding: 0 15px;
  }
  .about--header--image--2 {
    width: 290px;
  }
}


/* about header css ends */

/* about vision css starts */
.vision {
  background-color: #fff;
  text-align: center;
  padding: 20px 0;
}
.vission-flex{
  display: flex;
  justify-content: center;
}
.vission-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 80px;
}

@media only screen and (max-width: 1300px) {
  .vission-grid {
      gap: 50px;
  }
}
@media only screen and (max-width: 1024px) {
  .vission-grid {
      gap: 40px;
  }  
}
@media only screen and (max-width: 992px) {
  .vission-grid {
      grid-template-columns: repeat(1, 1fr);
  }
}

/* about vision css ends */


/* team css starts */
.alice-carousel__dots {
  display: none !important;
}

.alice-carousel__prev-btn-item, .alice-carousel__next-btn-item {
  padding: 5px 10px 5px 10px !important;
  color: #000 !important;
  box-shadow: rgb(17 17 26 / 28%) 0px 8px 24px, rgb(17 17 26 / 10%) 0px 16px 56px, rgb(17 17 26 / 10%) 0px 24px 80px !important;
  border-radius: 50% !important;
}
/* team css ends */

/* ourTeam css starts */
.item-image {
  max-width: 198px;
  max-height: 233px;
}
.item-image:hover {
  background-color: rgba(38, 48, 72, 0.35);
  opacity: 0.9;
  cursor: pointer;
}

.our-team-posi {
  font-size: 18px;
}

@media only screen and (max-width: 430px) {
  .our-team-name {
    font-size: 16px;
  }
  .our-team-posi {
    font-size: 14px;
  }
  .item-image {
    max-width: 130px;
    height: 160px;
  }
}
/* ourTeam css ends */

/* TechExpertise css starts */
.card-background {
  background-color: #FFFFFF;
  border-radius: 10px;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
  
}
.card-img {
padding: 20px;
width: 250px;
height: 137px;
display: flex;
flex-direction: row;
align-items: center;
}


/* TechExpertise css ends */

/* Technology css stats */


@media only screen and (max-width: 767px) {
  .custom-tailwind-cell {
    height: 135px;
  }
  .custom-tailwind-cell img {
    height: 135px;
    padding-top: 30%;
    padding-bottom: 30%;
  }
}
@media only screen and (max-width: 375px) {
  .custom-tailwind-cell {
    height: 135px;
  }
  .custom-tailwind-cell img {
    height: 135px;
    padding-top: 38%;
    padding-bottom: 33%;
  }
}
@media only screen and (max-width: 320px) {
  .custom-tailwind-cell {
    height: 135px;
  }
  .custom-tailwind-cell img {
    height: 135px;
    padding-top: 40%;
    padding-bottom: 40%;
  }
}
/* Technology css ends */

/* versionCard css starts */
.vision-img {
  width: 150px;
  height: 150px;
  margin-bottom: 20px;
}
.vision-item {
  width: 370px;
  border-radius: 10px;
  padding: 30px;
  overflow: hidden;
  text-align: center;
  border: none;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;   
}

.vision-title {
  margin-bottom: 15px;
}

@media only screen and (max-width: 1300px) {
  .vision-item {
      width: 350px;
  }
  .vision-img {
      width: 120px;
      height: 120px;
  }
  .vision-des {
      font-size: 16px;
  }
  .vision-title {
      font-size: 22px;
  } 
}

@media only screen and (max-width: 1200px) {
  .vision-item {
      width: 300px;
  }
  .vision-img {
      width: 115px;
      height: 115px;
  }
  .vision-des {
      font-size: 14px;
  }
  .vision-title {
      font-size: 21px;
  } 
}

@media only screen and (max-width: 1024px) {
  .vision-item {
      width: 280px;
  }
  .vision-img {
      width: 110px;
      height: 110px;
  }
  .vision-des {
      font-size: 13px;
  }
  .vision-title {
      font-size: 20px;
  }
}
@media only screen and (max-width: 768px) {
  .vision-item {
      width: 360px;
  }
  .vision-img {
      width: 120px;
      height: 120px;
  }
  .vision-des {
      font-size: 14px;
  }
  .vision-title {
      font-size: 22px;
  }
  .vision-item {
      width: 340px; 
      padding: 20px;
  }
}

@media only screen and (max-width: 400px) {
  .vision-item {
      width: 320px;
      padding: 15px; 
  }
}

@media only screen and (max-width: 320px) {
  .vision-item {
      width: 280px; 
  }
}

/* versionCard css ends */