.service-banner {
    padding-bottom: 20px;
}

.service-banner h1 {
    margin-bottom: 20px;
}

/* @media only screen and (min-width: 993px) {
    .service-banner {
        margin-bottom: 50px ;
    }
} */
@media only screen and (max-width: 1150px) {
    .service-img {
        width: 95%;
    }
}
/* @media only screen and (max-width: 992px) {
    .service-banner {
        margin-bottom: 50px;
    }
} */
@media only screen and (max-width: 570px) {
    .service-img {
        width: 90%;
    }
}