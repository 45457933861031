.planTable {
    background-color: #F9F8F8;
}
.plan-bg {
    background-color: #E7AA27 !important;
    color: #fff;
}
.monfont {
    font-family: 'Montserrat', sans-serif;
}
.g-color {
    color: #DF9237;
}
.plan-btn {
    border: none;
    background-color: #DF9135;
    color: #fff;
    padding: 10px 19px;
    border-radius: 5px;
}
.plan-b-btn {
    border: 1px solid #DF9135;
    background-color: transparent;
    color: #DF9135;
    padding: 10px 19px;
    border-radius: 5px; 
}
.border-g {
    border: 1px solid #DF9135 !important;
}
.border-bwg {
    border-top: 1px solid #DF9135 !important;
    border-right: 1px solid #DF9135 !important;
    border-left: 1px solid #DF9135 !important;
    border-bottom: 1px solid #fff !important;
}
.border-tbg {
    border-top: 1px solid #fff !important;
    border-right: 1px solid #DF9135 !important;
    border-left: 1px solid #DF9135 !important;
    border-bottom: 1px solid #fff !important;
}
.border-b {
    border-bottom: 1px solid #DF9135 !important;
}
.border-r {
    border-right: 1px solid #DF9135 !important;
}