.webSection {
    background-color: #F9F8F8;
}

.web-ul {
    padding-left: 0.9rem;
}

.web-ul li {
    list-style-type: none;
    font-weight: 600;
    margin-bottom: 4px;
}

.web-ul li::before{
    content: "\2022";
    color: #DF9135;
    font-weight: bolder;
    display: inline-block; 
    width: .9em;
    margin-left: -.9em;
}

@media only screen and (max-width: 992px) {
    .border-alround {
        border-right: 1px solid #DF9135;
        border-left: 1px solid #DF9135;
        border-bottom: 1px solid #DF9135;
    }
}