.custom-get-width {
  width: 75%;
}
@media only screen and (max-width: 767px) {
  .custom-get-width {
    width: 80%;
  }
  .adjust-padding {
    /* padding: 0 !important; */
    margin-top: 20px !important;
  }
}
@media only screen and (max-width: 600px) {
  .custom-get-width {
    width: 94% !important;
  }
}

.form-control {
  background-color: #f5f5f5 !important;
  border: none !important;
  padding: 10px 20px !important;
  margin: 20px 0;
}

.main--box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 50px;
}

.contactus--message--box {
  width: 350px;
  position: relative;
}

.contactus--white--box {
  width: 600px;
  height: 570px;
  left: -222px;
  top: -40px;
  background-color: white;
  position: absolute;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
  z-index: -10;
}

.contactus--info--box {
  width: 400px;
  height: 500px;
  background-color: #3f3d56;
  color: #fff;
  padding: 60px 40px;
  margin: 30px;
  position: relative;
}

.contactus--box--one {
  height: 50px;
  width: 50px;
  background-color: #e8ac25;
  position: absolute;
  top: -20px;
  left: -20px;
  z-index: -100;
}
.contactus--box--two {
  height: 80px;
  width: 80px;
  border: 15px solid #e8ac25;
  position: absolute;
  bottom: -30px;
  left: -30px;
  z-index: -100;
}
.contactus--box--three {
  border: 10px solid #3f3d56;
  width: 50px;
  height: 50px;
  position: absolute;
  right: -50px;
  top: -60px;
  transform: rotate(-4.77deg);
}
.contactus--box--four {
  background-color: #3f3d56;
  width: 100px;
  height: 100px;
  position: absolute;
  bottom: -66px;
  right: -55px;
  z-index: -100;
}

@media only screen and (max-width: 991px) {
  .contactus--white--box {
    display: none;
  }
  .contactus--box--three, .contactus--box--two,
  .contactus--box--four, .contactus--box--one {
    display: none;
  }
  .contactus--info--box {
    padding: 40px 30px;
    height: 350px;
    width: 400px;
    margin: 0;
    margin-bottom: 60px;
  }
  .main--box {
    flex-direction: column;
  }
}

@media only screen and (max-width: 480px) {
  .contactus--info--box {
    width: 320px;
  }
  .contactus--message--box {
    width: 320px;
  }
}
@media only screen and (max-width: 350px) {
  .contactus--info--box {
    width: 280px;
  }
  .contactus--message--box {
    width: 280px;
  }
}

.getintouch--sections {
  display: flex;
  align-items: center;
}

.contact--btn {
  background-color: #E8AC25 !important;
  height: 45px;
  padding: 0 !important;
}