.terms {
    border: 1px solid #E8AC25;
}

.custom-color{
    color: #E8AC25;
}

.terms-items {
    margin-bottom: 30px;
    border-bottom: 1px solid #636363;
}
.terms-items:last-child {
    margin-bottom: 0;
    border-bottom: none;
}

.terms-items p {
    padding-bottom: 14px;
}