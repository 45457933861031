.qus-content {
  width: 100%;
}
.break {
  width: 100%;
  height: 3px;
  background-color: #3f3d56;
  margin: 33px 0;
}
.qus-items{
  cursor: pointer;
}
.qus-items h5 {
  padding-bottom: 5px;
  font-weight: bold;
}

.qus-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.qus {
  cursor: pointer;
}
.questions-img {
  text-align: center;
}

@media only screen and (max-width: 992px) {
  .questions-img img {
    width: 280px;
  }
}

@media only screen and (max-width: 656px) {
  .qus-content {
    padding: 0 8px;
  }
}
