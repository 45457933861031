.contact--upper {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap-reverse;
}

.image--for--mobile {
  display: none;
}

.contact--upper div {
  margin: 20px 0;
  margin-top: 10px;
}

.contact--upper .text--part {
  margin: 20px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
}

.contact--upper .text--part h1 {
  font-weight: 600;
  margin-bottom: 25px;
  color: #000000;
}

/* 1012 */

@media only screen and (max-width: 1086px) {
  .image--for--mobile {
    display: block;
  }

  .image--for--pc {
    display: none;
  }

  .contact--upper .text--part h1 {
    text-align: center;
  }
}
