.container--yellow--box {
  display: flex;
  justify-content: center;
}

.yellow--box {
  background: #e8ac25;
  display: flex;
  width: 80%;
  border-radius: 4px 100px 100px 4px;
  height: 214px;
  position: relative;
  bottom: -80px;
}
.calendly--iframe{
  width: 100%;
  /* height: 100%; */
  /* height didn't worked do something else */
  height: 900px;
  border: none;
  border-radius: 4px 100px 100px 4px;
}

.yellow--box img {
  border-radius: 100%;
  height: 366px;
  position: relative;
  top: -76px;
}

.purple--box {
  background: #2f2d48;
  color: #fff;
  border-radius: 4px 4px 0 0;
  height: 292px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 35px;
}

.logo--copyright {
  margin-left: 60px;
}

.logo--copyright img {
  margin-bottom: 35px;
}

.purple--box p {
  margin: 0;
}

.get--in--touch {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.get--in--touch--button {
  border: 1.5px solid #000;
  padding: 10px 20px;
  border-radius: 4px;
  font-weight: 600;
  margin-top: 35px;
  color: #000;
}

.social--links {
  margin-right: 200px;
  display: flex;
}

.social--links div {
  margin: 5px;
}

@media only screen and (max-width: 1024px) {
  .get--in--touch h4 {
    font-size: 20px;
  }
  .yellow--box img {
    height: 300px;
    top: -46px;
  }
  .get--in--touch h4 {
    text-align: center;
  }
}

@media only screen and (max-width: 870px) {
  .yellow--box {
    height: 260px;
    top: 0px;
    background: #fff;
  }
  .yellow--box img {
    display: none;
  }
  .purple--box {
    height: 190px;
    align-items: flex-start;
    padding-top: 40px;
  }
  .logo--copyright p {
    font-size: 12px;
  }
  .social--links {
    margin-right: 100px;
    display: flex;
  }
}

@media only screen and (max-width: 610px) {
  .social--links {
    display: none;
  }
  .logo--copyright {
    margin: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media only screen and (max-width: 768px) {
  .container--yellow--box {
    display: none;
    }
}
