.web-body {
  min-height: 65vh;
  position: relative;
  margin-top: 6rem;
}

.app-version {
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  z-index: 10;
}

.app-version span {
  margin: 0.2rem;
  border: 1px solid #e6a428;
  padding: 0.2rem 0.5rem;
  border-radius: 0.4rem;
  color: #e6a428;
}

.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #e6a428 !important;
  outline: 0;
  box-shadow: none !important;
}

.btn:focus {
  box-shadow: none !important;
  outline: 0;
}

.form-check-input:focus {
  border-color: #333 !important;
  outline: 0;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
}

.form-check-input:checked[type='checkbox'] {
  background-image: url(./assets//images/check.png) !important;
}

.form-check-input:checked {
  background-color: #fff !important;
  border-color: #333 !important;
}

.form-select:focus {
  border-color: #e6a428 !important;
  outline: 0;
  box-shadow: none !important;
}

.bg-primary {
  background-color: #E7AA27;
}


