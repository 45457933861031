.serviceCard {
    background-color: #fff;
    width: 400px;
    overflow: hidden;
}
.service-link {
    color: #DF9135;
}
.service-link:hover {
    color: #f58c15;
}

.service-alround {
    border-right: 1px solid #DF9135;
    border-left: 1px solid #DF9135;
    border-bottom: 1px solid #DF9135;
}

@media only screen and (max-width: 776px) {
    .serviceCard {
        width: 350px;
    }
}
@media only screen and (max-width: 500px) {
    .serviceCard {
        width: 320px;
    }
}
@media only screen and (max-width: 340px) {
    .serviceCard {
        width: 310px;
    }
}